* {
  font-family: 'SF Pro Text', system-ui, sans-serif;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  background: #010101;
}

:root {
  --text-color: #FFFFFF;
  --reviews-io-widget-star-color: #EB731A;
  --reviews-io-widget-star-color-disabled: rgba(235, 115, 26, 0.5);
  --reviews-io-widget-header-star-color-disabled: rgba(255, 255, 255, 0.5);
  --reviews-io-widget-text-color: #F1F1F1;
  --reviews-io-widget-background-color: #373739;

  --single-choice-button-bg-color: #373739;
  --single-choice-button-color: #FFFFFF;
  --single-choice-button-bg-color-hover: #41352D;
  --single-choice-button-border: 3px solid #373739;
  --single-choice-button-bg-color-selected: #41352D;
  --single-choice-button-border-selected: 3px solid #EB731A;

    /*--single-choice-button-max-height: 56px;*/
    /*--single-choice-button-min-height: 56px;*/
    /*--multi-choice-button-max-height: 'unset';*/
    /*--multi-choice-button-min-height: 'unset';*/

    --multi-choice-button-bg-color-disabled: #1C1C1E;
    --multi-choice-button-color-disabled: #757575;
    --multi-choice-button-border-color-disabled: #1C1C1E;

    --checkbox-bg-color: #010101;
    --checkbox-color: #FFFFFF;

    --textareainput-bg-color: #373739;
    --textareainput-color: #FFFFFF;
    --textareainput-caret-color: #EB731A;
    --textareainput-label-color: #FFFFFF;
    --textareainput-label-color-active: #E6E6E6;
    --textareainput-char-counter-color: #EB731A;
    --textareainput-bg-image: linear-gradient(#EB731A, #EB731A);

    --tip-page-bg: #010101;
    --tip-page-color: #FFFFFF;

  --rating-button-bg-color-selected: #EB731A;
}

/* @description: Hack for Paywall - for "OR" text */
div[class^="divider"], #primerio-container {
  font-family: 'SF Pro Text', serif;
}

span {
  font-weight: 400;
}
